$red: #db4433;
$black:#000000;


$primary: $red;
$dark:$black;
$white:#ffffff;

$font-family-sans-serif: 'Montserrat', sans-serif;

$border-radius-sm: .35rem;
$border-radius: .45rem;
$border-radius-lg: .55rem;

// couleurs 2025

$blue:#0384B1;
$jaune:#edb502;

$primary: $blue;

$menuActifBackGround:$black;
$menuActifColor:$jaune;

$titrePageBackGround:$jaune;
$titrePageColor:$white;

$asideInscriptionTitleColor:$jaune;

$rougeDoras:$red;

//
//:root {
//  --blue: #03b84B1;
//}