@import '../../common/components/form-wizard';

.text-rouge-doras {
  color:$rougeDoras;
}
.titre-page {
  background-color: $titrePageBackGround;
  color: $white;
}
.aside-inscription-title {
  color:$asideInscriptionTitleColor;
}

.bg-yellow {
  background-color: $jaune;
}

#sidebar {
  .nav-item {
    + .nav-item {
      border-top: 1px solid $text-muted;
    }
  }
.nav-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1em;
  padding-right: 1em;
  color: $black;
  &.active {
    background-color: $menuActifBackGround;
    color: $menuActifColor;
    font-weight: bold;
    svg {
      display: none;
    }
  }
}
}

.footer-logo {
  max-height: 70px;
}

.footer-links {
  .nav-item {
    display: flex;
    align-items: center;
    font-size: $font-size-base * 0.625;
    .nav-link {
      color: $white;
      text-transform: uppercase;
      font-style: italic;
      font-weight: $font-weight-bold;
      padding-left: 0;
      padding-right: 0;
    }
    + .nav-item {
      &:before {
        content: "•";
        display: block;
        margin: 0 .5em;
        color: $white;
      }
    }
  }
}

.footer-icons {
  .nav-item {
    .nav-link {
      padding-left: 0;
      padding-right: 0;
    }
    + .nav-item {
      margin-left: .5em;
    }
  }
}
